import moment from 'moment'

export const promotionTypes = [
  { isOneTime: true, value: 'Разова' },
  { isOneTime: false, value: 'Постійна' },
]

export const valueTypes = [
  { id: 1, value: 'грн.' },
  { id: 2, value: '%' },
]

export const mobileParams = {
  promotion_header: 'Відображати рекламний заголовок і розмір кешбека в % для клієнта',
  show_service_point: 'Відображати торгові точки, на яких доступна акція',
  show_device: 'Відображати автомати, на яких доступна акція',
  show_customer_bonus_account: 'Відображати бонусний рахунок клієнта',
  show_calculator: 'Відображати калькулятор',
  bonus_account_replenishment: 'Відображати кнопки "Способи оплати"',
  show_promotion_progress: 'Відображати прогрес клієнта',
  invite_friend: 'Відображати кнопку "Запросити"',
}

export const PROMOTION_PARAMS = [
  {
    // Глобальна акція "Приведи друга"
    type: 4,
    start_at: { editable: true, required: true, presetValue: moment(new Date()).startOf('day').format('YYYY-MM-DD HH:mm:ss') },
    title: { editable: true, required: true },
    description: { editable: true, required: true },
    value_type: { editable: false, presetValue: 1 },
    amount: { editable: true, required: true, minValue: 1 },
    created_by: { editable: false, required: true },
    params: ['promotion_header', 'invite_friend', 'show_promotion_progress'],
  },
  {
    // Подарунок-зарахування на бонусний рахунок
    type: 6,
    title: { editable: true, required: true },
    description: { editable: true, required: true },
    users: { editable: true, required: true },
    value_type: { presetValue: 1, hide: true },
    amount: { editable: true, required: true, minValue: 1 },
    created_by: { editable: false, required: true },
  },
  {
    // Додаткові бонуси на поповнення бонусного рахунку з картки
    type: 11,
    is_active: { editable: true, required: true, presetValue: true },
    start_at: { editable: true, required: true, presetValue: moment(new Date()).startOf('day').format('YYYY-MM-DD HH:mm:ss') },
    expired_at: { editable: true },
    title: { editable: true, required: true },
    description: { editable: true, required: true },
    conditions: { editable: true, required: true, valueTypeDefault: 2 },
    params: ['show_calculator', 'bonus_account_replenishment', 'show_customer_bonus_account'],
    created_by: { editable: false, required: true },
  },
  {
    // Кешбек на першу покупку після реєстрації клієнта
    type: 12,
    is_active: { editable: true, required: true, presetValue: true },
    start_at: { editable: true, required: true, presetValue: moment(new Date()).startOf('day').format('YYYY-MM-DD HH:mm:ss') },
    expired_at: { editable: true },
    title: { editable: true, required: true },
    description: { editable: true, required: true },
    value_type: { editable: true, presetValue: 2, hide: false },
    amount: { editable: true, required: true },
    params: ['promotion_header', 'show_customer_bonus_account'],
    created_by: { editable: false, required: true },
  },
  {
    // Кешбек на першу покупку на автоматі
    type: 13,
    is_active: { editable: true, required: true, presetValue: true },
    start_at: { editable: true, required: true, presetValue: moment(new Date()).startOf('day').format('YYYY-MM-DD HH:mm:ss') },
    expired_at: { editable: true },
    title: { editable: true, required: true },
    description: { editable: true, required: true },
    value_type: { editable: true, presetValue: 2, hide: false },
    devices: { editable: true, required: true },
    amount: { editable: true, required: true },
    created_by: { editable: false, required: true },
    params: ['promotion_header', 'show_device'],
  },
  {
    // Кешбек на першу покупку на торговій точці
    type: 14,
    is_active: { editable: true, required: true, presetValue: true },
    start_at: { editable: true, required: true, presetValue: moment(new Date()).startOf('day').format('YYYY-MM-DD HH:mm:ss') },
    expired_at: { editable: true },
    title: { editable: true, required: true },
    description: { editable: true, required: true },
    value_type: { editable: true, presetValue: 2, hide: false },
    service_points: { editable: true, required: true },
    amount: { editable: true, required: true },
    created_by: { editable: false, required: true },
    params: ['promotion_header', 'show_service_point'],
  },
  {
    // Програма лояльності - кешбек
    type: 15,
    is_active: { editable: true, required: true, presetValue: true },
    start_at: { editable: true, required: true, presetValue: moment(new Date()).startOf('day').format('YYYY-MM-DD HH:mm:ss') },
    expired_at: { editable: true },
    title: { editable: true, required: true },
    description: { editable: true, required: true },
    conditions: { editable: true, required: true, valueTypeOnly: 2 },
    created_by: { editable: false, required: true },
    params: ['promotion_header', 'show_customer_bonus_account', 'show_promotion_progress'],
  },
  {
    // Кешбек на день народження
    type: 16,
    is_active: { editable: true, required: true, presetValue: true },
    start_at: { editable: true, required: true, presetValue: moment(new Date()).startOf('day').format('YYYY-MM-DD HH:mm:ss') },
    expired_at: { editable: true },
    title: { editable: true, required: true },
    description: { editable: true, required: true },
    value_type: { editable: true, presetValue: 2, hide: false },
    amount: { editable: true, required: true },
    created_by: { editable: false, required: true },
    params: ['promotion_header', 'show_customer_bonus_account'],
  },
  {
    // Кешбек в залежності від суми покупок на автоматі
    type: 17,
    is_active: { editable: true, required: true, presetValue: true },
    start_at: { editable: true, required: true, presetValue: moment(new Date()).startOf('day').format('YYYY-MM-DD HH:mm:ss') },
    expired_at: { editable: true },
    title: { editable: true, required: true },
    description: { editable: true, required: true },
    conditions: { editable: true, required: true, valueTypeOnly: 2 },
    devices: { editable: true, required: true },
    created_by: { editable: false, required: true },
    params: ['promotion_header', 'show_device'],
  },
  {
    // Кешбек в залежності від суми покупок на торговій точці
    type: 18,
    is_active: { editable: true, required: true, presetValue: true },
    start_at: { editable: true, required: true, presetValue: moment(new Date()).startOf('day').format('YYYY-MM-DD HH:mm:ss') },
    expired_at: { editable: true },
    title: { editable: true, required: true },
    description: { editable: true, required: true },
    conditions: { editable: true, required: true, valueTypeOnly: 2 },
    service_points: { editable: true, required: true },
    created_by: { editable: false, required: true },
    params: ['promotion_header', 'show_service_point'],
  },
  // Кешбек - програма лояльності для конкретних користувачів
  {
    type: 19,
    is_active: { editable: true, required: true, presetValue: true },
    start_at: { editable: true, required: true, presetValue: moment(new Date()).startOf('day').format('YYYY-MM-DD HH:mm:ss') },
    expired_at: { editable: true },
    title: { editable: true, required: true },
    description: { editable: true, required: true },
    users: { editable: true, required: true },
    conditions: { editable: true, required: true, valueTypeOnly: 2 },
    created_by: { editable: false, required: true },
    params: ['promotion_header', 'show_customer_bonus_account'],
  },
]
