<template>
  <v-dialog v-model="isActive" max-width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn :disabled="!useRanges" v-on="on" v-bind="attrs">{{ buttonTitle }}</v-btn>
    </template>
    <v-card>
      <FormToolbar>
        <template v-slot:left>
          <v-toolbar-title> Діапазони акції </v-toolbar-title>
        </template>
        <template v-slot:right>
          <v-btn
            :disabled="
              (!!conditions.length && conditions[conditions.length - 1].condition_amount_to === null) || readonly || showAddArea
            "
            @click="addRange"
            >Додати діапазон</v-btn
          >
        </template>
      </FormToolbar>
      <div v-for="(cond, index) in conditions" :key="index">
        <ConditionsArrayItem
          :condition="cond"
          :index="index + 1"
          :params="params"
          :from-disabled="!!conditions.length && !!editedIndex"
          :to-disabled="editedIndex < conditions.length - 1"
          :delete-disabled="(index < conditions.length - 1 && index > 0) || readonly"
          readonly
          @save="updateRange"
          @edit="editedIndex = index"
          @cancel="editedIndex = -1"
          @delete="deleteRange"
        />
        <DeleteDialog
          :is-opened="dialogDelete"
          :message="`Ви впевнені, що хочете видалити діапазон ${index + 1}?`"
          @yes="deleteConfirm"
          @no="closeDelete"
        />
      </div>
      <v-card-title v-if="!conditions.length && !showAddArea">Діапазонів не задано</v-card-title>
      <ConditionsArrayItem
        v-if="showAddArea"
        :index="conditions.length + 1"
        :condition="condition"
        :params="params"
        :from-disabled="!!conditions.length && !!editedIndex"
        @save="createRange"
        @cancel="showAddArea = false"
      />
      <v-divider />
      <v-card-actions v-if="!showAddArea && editedIndex === -1">
        <v-spacer />
        <v-btn text @click="close">Повернутись</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { omit } from 'lodash'
import FormToolbar from '@/components/common/forms/FormToolbar'
import ConditionsArrayItem from '@/components/marketing/promotions/ConditionsArrayItem'
import DeleteDialog from '@/components/dialogs/DeleteDialog'

export default {
  name: 'ConditionsArrayModal',
  components: { DeleteDialog, ConditionsArrayItem, FormToolbar },

  emits: ['create', 'update', 'delete'],

  mixins: [validationMixin],

  props: {
    values: {
      type: Array,
      default: () => [],
    },
    useRanges: {
      type: Boolean,
      required: true,
    },
    params: {
      type: Object,
      required: true,
    },
    isError: {
      type: Boolean,
      default: false,
    },
    buttonTitle: {
      type: String,
      default: 'Редагувати...',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isActive: false,
      showAddArea: false,
      dialogDelete: false,
      conditions: [],
      condition: {},
      firstCondition: {
        condition_amount_from: 0.01,
        condition_amount_to: null,
        type: this.params.valueTypeDefault || 2,
        amount: 1,
      },
      editedIndex: -1,
    }
  },

  created() {
    this.initialize()
  },

  watch: {
    values: {
      handler(val) {
        this.conditions = [...val]
      },
      deep: true,
    },
  },

  methods: {
    initialize() {
      this.conditions = [...this.values]
    },

    addRange() {
      if (this.conditions.length) {
        const lastCondition = this.conditions[this.conditions.length - 1]
        const newCondition = {
          ...omit(lastCondition, 'id'),
          condition_amount_from: lastCondition.condition_amount_to + 0.01,
          condition_amount_to: null,
        }
        this.condition = { ...newCondition }
      } else {
        this.condition = { ...this.firstCondition }
      }
      this.showAddArea = true
    },

    close() {
      this.isActive = false
      this.showAddArea = false
    },

    createRange(range) {
      this.condition = { ...range }
      this.$emit('create', this.condition)
      this.showAddArea = false
    },

    updateRange(range) {
      this.condition = { ...range }
      this.$emit('update', { payload: this.condition, index: this.editedIndex })
      this.editedIndex = -1
    },

    deleteRange(index) {
      this.editedIndex = index
      this.dialogDelete = true
    },

    closeDelete() {
      this.editedIndex = -1
      this.dialogDelete = false
    },

    deleteConfirm() {
      this.$emit('delete', this.editedIndex)
      this.closeDelete()
    },
  },
}
</script>
